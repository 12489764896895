import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page-mdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Cat's study guide for Yang Family Tai Chi info.`}</p>
    <h3>{`Five Styles and Founders`}</h3>
    <ol>
      <li parentName="ol">{`Chen - Chen Wang Ting`}</li>
      <li parentName="ol">{`Yang - Yang Lu Chan`}</li>
      <li parentName="ol">{`Wu/Hao - Wu Yu Xiang`}</li>
      <li parentName="ol">{`Wu - Wu Jian Chuan`}</li>
      <li parentName="ol">{`Sun - Sun Lu Tang`}</li>
    </ol>
    <h3>{`Yang Family Lineage`}</h3>
    <ol>
      <li parentName="ol">{`Yang Lu Chan (old frame)`}<ul parentName="li">
          <li parentName="ul">{`Small frame:`}<ul parentName="li">
              <li parentName="ul">{`Yang Ban Hou`}</li>
              <li parentName="ul">{`Yang Shao Hou`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ol">{`Yang Jian Hou (medium frame)`}</li>
      <li parentName="ol">{`Yang Cheng Fu (large frame)`}</li>
      <li parentName="ol">{`Yang Zhen Duo`}</li>
      <li parentName="ol">{`Yang Jun`}</li>
    </ol>
    <h3>{`Birthplace of Yang Style Tai Chi`}</h3>
    <p>{`Hebei Province, Guangping Prefecture, Yongnian County`}</p>
    <h3>{`Characteristics of Yang Style Tai Chi`}</h3>
    <p>{`Large and graveful, with even and slow speed.`}</p>
    <h3>{`Yang Family System of Training`}</h3>
    <ol>
      <li parentName="ol">{`Hand Form`}</li>
      <li parentName="ol">{`Sword Form`}</li>
      <li parentName="ol">{`Saber Form`}</li>
      <li parentName="ol">{`Push Hands`}</li>
      <li parentName="ol">{`Staff Form`}</li>
    </ol>
    <h3>{`10 Essential Principles`}</h3>
    <ol>
      <li parentName="ol">{`Empty, Lively, Pushing up, and Energetic.`}</li>
      <li parentName="ol">{`Sink the chest / pull  up the back.`}</li>
      <li parentName="ol">{`Relax the waist.`}</li>
      <li parentName="ol">{`Separate empty and full.`}</li>
      <li parentName="ol">{`Sink the shoulders, drop the elbows.`}</li>
      <li parentName="ol">{`Use Intent rather than Force.`}</li>
      <li parentName="ol">{`Synchronize Upper and Lower.`}</li>
      <li parentName="ol">{`Match Up Inner and Outer.`}</li>
      <li parentName="ol">{`Practice Continuously and Without Interruption.`}</li>
      <li parentName="ol">{`Seek quiescence within movement.`}</li>
    </ol>
    <h3>{`Eight Energies`}</h3>
    <ol>
      <li parentName="ol">{`Ward Off - Peng`}</li>
      <li parentName="ol">{`Roll Back - Lu`}</li>
      <li parentName="ol">{`Press - Ji`}</li>
      <li parentName="ol">{`Push - An`}</li>
      <li parentName="ol">{`Pull/Pluck - Cai`}</li>
      <li parentName="ol">{`Split - Lie`}</li>
      <li parentName="ol">{`Elbow - Zhou`}</li>
      <li parentName="ol">{`Shoulder - Kao`}</li>
    </ol>
    <h3>{`Five Steps`}</h3>
    <ol>
      <li parentName="ol">{`Advancing`}</li>
      <li parentName="ol">{`Retreating`}</li>
      <li parentName="ol">{`Look Left`}</li>
      <li parentName="ol">{`Gaze Right`}</li>
      <li parentName="ol">{`Central Equilibrium / Settle at the Center`}</li>
    </ol>
    <h3>{`Fang Song`}</h3>
    <p>{`Relaxation means to open the joints, tendons, and bones so that the whole body is unified.`}</p>
    <h3>{`Energy is...`}</h3>
    <p>{`ROOTED in the FEET.`}</p>
    <p>{`GENERATED by the LEG`}</p>
    <p>{`CONTROLLED by the WAIST`}</p>
    <p>{`EXPRESSED in the HANDS`}</p>
    <h3>{`The function of the waist is...`}</h3>
    <p>{`...to lead the movements.`}</p>
    <h3>{`Bow Stance Characteristics`}</h3>
    <ul>
      <li parentName="ul">{`Weight: 60% front leg, 40% back leg`}</li>
      <li parentName="ul">{`Front foot points forward, back foot at 45 degrees`}</li>
      <li parentName="ul">{`Knees in line with toes.`}</li>
      <li parentName="ul">{`Feet shoulder width apart.`}</li>
      <li parentName="ul">{`Front leg is full.`}</li>
    </ul>
    <h3>{`Empty Stance Characteristics`}</h3>
    <ul>
      <li parentName="ul">{`Weight is 30% front, 70% back`}</li>
      <li parentName="ul">{`Weight on back leg.`}</li>
      <li parentName="ul">{`Front foot points forward, back foot at 45 degrees`}</li>
      <li parentName="ul">{`Front foot doesn’t cross the center line of the supporting heel.`}</li>
      <li parentName="ul">{`Knee points in the toe direction`}</li>
      <li parentName="ul">{`Heel stance: leg naturally straight`}</li>
      <li parentName="ul">{`Ball stance: leg slightly bent`}</li>
    </ul>
    <h3>{`3 Postures with Upright Body:`}</h3>
    <ul>
      <li parentName="ul">{`Ward Off Left`}</li>
      <li parentName="ul">{`Fan Through Back`}</li>
      <li parentName="ul">{`Single Whip`}</li>
    </ul>
    <h3>{`Saber Form`}</h3>
    <p>{`The movements of the saber form are large, graceful. The speed is faster than the hand and sword forms and shows a strong spirit, energy, heaviness and power.`}</p>
    <h3>{`Sword Form`}</h3>
    <p>{`The sword techniques are clear and agile (light, flexible, lively, flowing). The movements are large and graceful with even speed.`}</p>
    <h3>{`Martial Code of Conduct`}</h3>
    <p>{`The established code of conduct for martial artists is called `}<em parentName="p">{`Wu De`}</em>{` in Chinese.`}</p>
    <p>{`Martial Virtue covers two main areas: the actions and the mindset of the fighter.
In activity, one should express Humility, Respect, Righteousness, Trust and Loyalty.
Mentally, one must have Will, Endurance, Perseverance, Patience and Courage.`}</p>
    <h3>{`General`}</h3>
    <p>{`What is the meaning of empty lively pushing up and energetic?`}</p>
    <p>{`What does wu de mean to you?`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      