import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const MarkdownPage = ({ children, pageContext }) => {
  const {
    title,
    keywords = ['cooperative', 'shenanigans', 'games'],
  } = pageContext.frontmatter

  return (
    <Layout>
      <SEO title={title} keywords={keywords} />
      <div>{children}</div>
    </Layout>
  )
}

export default MarkdownPage
